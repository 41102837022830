import {
  SORTING_NEW,
  SORTING_OLD,
  SORTING_READ,
  SORTING_UNREAD,
  TranslationDictionary
} from '@plandi/common'

export enum MESSAGES_SERVICE_TYPE {
  ADVERTS = 'adverts',
  SHOP = 'shop'
}

//export const MESSAGE_SERVICE_TYPES = {
export const getMessageServiceType = (
  translate: TranslationDictionary['data']
) => {
  return {
    [MESSAGES_SERVICE_TYPE.ADVERTS]: translate?.job.title2,
    [MESSAGES_SERVICE_TYPE.SHOP]: translate?.market.title
  }
}

//export const MESSAGE_SERVICE_TYPE_OPTIONS = Object.entries(
export const getMessageServiceTypeOptions = (
  translate: TranslationDictionary['data']
) => Object.entries(getMessageServiceType(translate))

export const MESSAGES_SORT_OPTIONS = [
  SORTING_NEW,
  SORTING_OLD,
  SORTING_READ,
  SORTING_UNREAD
]

export const ADVERTS_MESSAGES_DATA = 'ADVERTS_MESSAGES_DATA'
export const SHOP_MESSAGES_DATA = 'SHOP_MESSAGES_DATA'
export const UNREAD_MESSAGES = 'UNREAD_MESSAGES'

export type messagesSortOptions =
  | typeof SORTING_NEW
  | typeof SORTING_OLD
  | typeof SORTING_READ
  | typeof SORTING_UNREAD

export enum sortOrder {
  ASCEND = 'asc',
  DESCEND = 'desc'
}

export type messageSearchOptions = {
  page: number
  perPage: number
  keyword?: string
  sort: sortOrder
}

import React from 'react'
import clsx from 'clsx'
import Head from 'next/head'

import {
  // LayoutFooter,
  TranslationDictionary
  // useLocale,
  // useTranslation
} from '@plandi/common'
import {
  useLayoutStyles,
  LayoutProps,
  LayoutContent,
  NewFooter,
  NewHeader
} from '../common'
import { useMediaQuery, useTheme } from '@material-ui/core'

export type MainLayoutProps = {
  withSidebar?: boolean
  isShowFooter?: boolean
  showHelp?: boolean
  sidebarWidth?: number
  sidebar?: React.ReactNode
  maxWidth?: number
  center?: boolean
  profileHeader?: boolean
  title?: string
  description?: string
  image?: string
  keywords?: string[]
  profileHeaderTitle?: string
  containerStyles?: React.CSSProperties
  isNotification?: boolean
  translate?: TranslationDictionary['data']
  newBg?: boolean
}

export type breadcrumbInfoType = {
  title: string
  href: string
}

export const MainLayout: React.FC<LayoutProps & MainLayoutProps> = (props) => {
  const classes = useLayoutStyles()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))
  const centerStyles = props.center
    ? { margin: '120px auto 0', padding: isDesktop ? '0 24px' : 0 }
    : {}
  // const { data: translate } = useTranslation()
  // const locale = useLocale()

  return (
    <>
      <Head>
        <title>
          {props.description
            ? props.title
            : `PlanDi${props.title ? ` - ${props.title}` : ''}`}
        </title>
        {props.description && (
          <meta name="description" content={props.description} />
        )}
        {/* {props.keywords && (
          <meta name="keywords" content={props.keywords.join(' ')} />
        )} */}
        <meta property="og:type" content="website" />
        <meta
          property="og:title"
          content={
            props.description
              ? props.title
              : `PlanDi${props.title ? ` - ${props.title}` : ''}`
          }
        />
        {props.description && (
          <meta property="og:description" content={props.description} />
        )}
        <meta
          property="og:image"
          content={
            props.image
              ? props.image
              : 'https://plandi-storage.storage.yandexcloud.net/storage/TI/PP/tipp4JkIew0vBYBZe1T2huoavmu934CkntGKFLvA.jpg'
          }
        />
        <meta name="twitter:card" content="summary_large_image" />
        <meta
          name="twitter:title"
          content={
            props.description
              ? props.title
              : `PlanDi${props.title ? ` - ${props.title}` : ''}`
          }
        />
        {props.description && (
          <meta name="twitter:description" content={props.description} />
        )}
        <meta
          name="twitter:image"
          content={
            props.image
              ? props.image
              : 'https://plandi-storage.storage.yandexcloud.net/storage/TI/PP/tipp4JkIew0vBYBZe1T2huoavmu934CkntGKFLvA.jpg'
          }
        />
        <meta
          name="facebook-domain-verification"
          content="caobbgwm9gzssuirv51cqsskz3hf41"
        />
      </Head>
      <div
        className={clsx(classes.root, props.className, {
          [classes.redesignedBg]: props.newBg
        })}
      >
        <NewHeader />
        <div
          style={{
            ...centerStyles,
            ...props.containerStyles
          }}
          className={classes.main}
        >
          <LayoutContent
            withSidebar={props.withSidebar}
            sidebarWidth={props.sidebarWidth}
          >
            {props.children}
          </LayoutContent>
        </div>
        {props.isShowFooter && (
          <div className={classes.footer}>
            {/*<LayoutFooter*/}
            {/*  translate={translate}*/}
            {/*  locale={locale}*/}
            {/*  showHelp={props.showHelp}*/}
            {/*/>*/}
            <NewFooter />
          </div>
        )}
      </div>
    </>
  )
}

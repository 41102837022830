import React, { useRef } from 'react'
import {
  TranslationDictionary,
  // getServiceURL,
  SERVICES_URL,
  useDisclosure
} from '@plandi/common'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown'
import IconButton from '@material-ui/core/IconButton'

import { useLayoutHeaderMenuStyles } from './layout-header-menu.styles'
import { Link } from '@material-ui/core'
import { useRouter } from 'next/router'

type LayoutMenuItem = {
  title: string
  href: string
  active?: boolean
}

type Props = {
  userDidSignOut?: boolean
  translate?: TranslationDictionary['data']
  // locale: string
}

export const LayoutHeaderMenu: React.FC<Props> = ({ translate }) => {
  const url = SERVICES_URL
  const { locale } = useRouter()
  const main: LayoutMenuItem[] = [
    {
      title: translate?.['market']?.['title'] ?? 'Проектный маркет',
      href: locale === 'ru' ? `${url.shop}/ru` : url.shop
    },
    {
      title: translate?.['job']?.['title2'] ?? 'Фриланс и работа',
      href: locale === 'ru' ? `${url.job}/ru` : url.job
    },
    {
      title: translate?.office?.title ?? 'Онлайн Офис',
      href: locale === 'ru' ? `${url.office}/ru` : url.office
    },
    {
      title: translate?.bignav?.blog?.title ?? 'Блог',
      href: locale === 'ru' ? `${url.blog}/ru/articles` : `${url.blog}/articles`
    }
  ]

  const classes = useLayoutHeaderMenuStyles()

  const menuRef = useRef()

  const mainDisclosure = useDisclosure()

  return (
    <div className={classes.container}>
      <>
        <IconButton
          onClick={mainDisclosure.onToggle}
          innerRef={menuRef}
          size="small"
          className={classes.sections}
        >
          {translate?.['job-site']?.sections ?? 'Разделы'}
          <ArrowDropDownIcon />
        </IconButton>
        <Menu
          anchorEl={menuRef.current}
          open={mainDisclosure.isOpen}
          onClose={mainDisclosure.onClose}
        >
          {main.map((tab) => (
            <Link
              href={tab.href}
              key={tab.href}
              color="inherit"
              underline="none"
            >
              <MenuItem button>{tab.title}</MenuItem>
            </Link>
          ))}
        </Menu>
      </>
    </div>
  )
}

import { useQuery, UseQueryOptions } from 'react-query'
import { messagesApi } from '../messages.api'
import {
  messageSearchOptions,
  sortOrder,
  UNREAD_MESSAGES
} from '../messages.constants'
import { components } from '@plandi/common/src/generated'
import { AxiosError } from 'axios'
import { useRouter } from 'next/router'
import { useLocale } from '@plandi/common'

export const useUnreadMessageQuery = () => {
  const locale = useLocale()
  return useQuery({
    queryFn: () => messagesApi.getUnreadMessages(locale),
    queryKey: [UNREAD_MESSAGES, locale]
  })
}
export const useProjectChatsQuery = (
  options?: UseQueryOptions<
    components['schemas']['MessagesProjectsResponse'],
    AxiosError,
    components['schemas']['MessagesProjectsResponse']
  >
) => {
  const router = useRouter()
  const locale = useLocale()
  const params: messageSearchOptions = {
    sort: (router.query.sort as sortOrder) ?? sortOrder.DESCEND,
    page: router.query.page ? parseInt(router.query.page as string) : 1,
    perPage: 10,
    keyword: (router.query.keyword as string) ?? ''
  }
  return useQuery(
    ['PROJECT_MESSAGES', params, locale],
    () => messagesApi.getProjectsChats(locale, params),
    {
      ...options
    }
  )
}

export const useModelChatsQuery = (
  options?: UseQueryOptions<
    components['schemas']['MessagesModelsResponse'],
    AxiosError,
    components['schemas']['MessagesModelsResponse']
  >
) => {
  const router = useRouter()
  const locale = useLocale()
  const params: messageSearchOptions = {
    sort: (router.query.sort as sortOrder) ?? sortOrder.DESCEND,
    page: router.query.page ? parseInt(router.query.page as string) : 1,
    perPage: 10,
    keyword: (router.query.keyword as string) ?? ''
  }
  return useQuery(
    ['MODELS_MESSAGES', params, locale],
    () => messagesApi.getModelsChats(locale, params),
    {
      ...options
    }
  )
}

export const useCompanyChatsQuery = (
  options?: UseQueryOptions<
    components['schemas']['CompanyMessagesResponse']['data'],
    AxiosError,
    components['schemas']['CompanyMessagesResponse']['data']
  >
) => {
  const router = useRouter()
  const locale = useLocale()
  const params: messageSearchOptions = {
    sort: (router.query.sort as sortOrder) ?? sortOrder.DESCEND,
    page: router.query.page ? parseInt(router.query.page as string) : 1,
    perPage: 10,
    keyword: (router.query.keyword as string) ?? ''
  }
  return useQuery(
    ['COMPANY_CHATS', params, locale],
    () => messagesApi.getCompaniesChats(locale, params),
    {
      ...options
    }
  )
}

export const useSpecialistChatsQuery = (
  options?: UseQueryOptions<
    components['schemas']['SpecialistMessagesResponse']['data'],
    AxiosError,
    components['schemas']['SpecialistMessagesResponse']['data']
  >
) => {
  const router = useRouter()
  const locale = useLocale()
  const params: messageSearchOptions = {
    sort: (router.query.sort as sortOrder) ?? sortOrder.DESCEND,
    page: router.query.page ? parseInt(router.query.page as string) : 1,
    perPage: 10,
    keyword: (router.query.keyword as string) ?? ''
  }
  return useQuery(
    ['SPECIALIST_CHATS', params, locale],
    () => messagesApi.getSpecialistChats(locale, params),
    {
      ...options
    }
  )
}

export const useFactoriesChatsQuery = (
  options?: UseQueryOptions<
    components['schemas']['FactoryMessagesResponse']['data'],
    AxiosError,
    components['schemas']['FactoryMessagesResponse']['data']
  >
) => {
  const router = useRouter()
  const locale = useLocale()
  const params: messageSearchOptions = {
    sort: (router.query.sort as sortOrder) ?? sortOrder.DESCEND,
    page: router.query.page ? parseInt(router.query.page as string) : 1,
    perPage: 10,
    keyword: (router.query.keyword as string) ?? ''
  }
  return useQuery(
    ['FACTORIES_CHATS', params, locale],
    () => messagesApi.getFactoriesChats(locale, params),
    {
      ...options
    }
  )
}

import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useLayoutContentStyles = makeStyles((theme) =>
  createStyles({
    content: {
      flexGrow: 1,
      padding: theme.spacing(0),
      display: 'flex',
      width: '100%',
      flexDirection: 'column',
      overflow: 'hidden',
      overflowX: 'auto',
      transition: theme.transitions.create('margin', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen
      }),

      [theme.breakpoints.up('md')]: {
        padding: theme.spacing(0)
      }
    }
  })
)

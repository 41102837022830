import { createStyles, makeStyles } from '@material-ui/core'

export const useShopMessageItemStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      gap: 24,
      padding: '24px 20px',
      backgroundColor: '#fff',
      cursor: 'pointer',
      [theme.breakpoints.down('xs')]: {
        gap: 0
      }
    },

    itemTop: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        justifyContent: 'normal',
        alignItems: 'normal',
        gap: 12
      }
    },

    itemInfo: {
      display: 'flex',
      alignItems: 'center',
      gap: 16,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        alignItems: 'normal'
      }
    },

    img: {
      [theme.breakpoints.down('xs')]: {
        alignSelf: 'center'
      }
    },

    itemInfoContainer: {},

    name: {
      fontWeight: 500,
      color: '#212121',
      lineHeight: '28px',
      fontSize: 18,
      letterSpacing: '0.5px',
      [theme.breakpoints.down('md')]: {
        fontSize: 16
      },
      [theme.breakpoints.down('md')]: {
        fontSize: 18
      }
    },

    category: {
      color: '#757575',
      fontSize: 14,
      lineHeight: '20px',
      letterSpacing: '0.25px'
    },

    itemMetadata: {
      display: 'flex',
      gap: 24,
      [theme.breakpoints.down('xs')]: {
        flexDirection: 'column',
        gap: 12
      }
    },

    date: {
      color: '#757575',
      lineHeight: '20px',
      fontSize: 14,
      letterSpacing: '0.25px',
      margin: 0,
      [theme.breakpoints.down('md')]: {
        fontSize: 12
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 14
      }
    },

    price: {
      color: '#212121',
      fontWeight: 500,
      letterSpacing: '0.25px',
      lineHeight: '20px',
      fontSize: 14,
      margin: 0,
      [theme.breakpoints.down('md')]: {
        fontSize: 12
      },
      [theme.breakpoints.down('xs')]: {
        fontSize: 14
      }
    },

    itemBottom: {},

    itemText: {
      fontSize: 14,
      maxWidth: '100%',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      [theme.breakpoints.down('md')]: {
        maxWidth: '81%',
        fontSize: 14
      },
      [theme.breakpoints.down('sm')]: {
        whiteSpace: 'normal',
        maxHeight: 85,
        maxWidth: '100%',
        lineHeight: '20px'
      }
    }
  })
)

import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useLayoutHeaderMenuStyles = makeStyles((theme) =>
  createStyles({
    activeTab: {
      color: theme.palette.secondary.main
    },

    name: {
      marginRight: 18,
      [theme.breakpoints.down(1503)]: {
        marginRight: 9
      }
    },

    sections: {
      padding: 5,
      borderRadius: 4,
      fontSize: 16,
      color: '#000000DE',
      fontWeight: 500,
      [theme.breakpoints.down(1280)]: {
        marginLeft: 20
      },
      [theme.breakpoints.down(450)]: {
        marginLeft: 5
      }
    },

    sectionsSub: {
      padding: '8px 16px',
      borderRadius: 4,
      fontSize: 18,
      color: '#E64147',
      [theme.breakpoints.down(960)]: {
        fontSize: 14
      }
    },

    icon: {
      opacity: 0,

      [theme.breakpoints.up('lg')]: {
        opacity: 1
      },

      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },

    button: {
      marginRight: 0,
      textTransform: 'none',
      fontSize: 16
    },

    resp: {
      marginRight: 22,
      fontSize: 16,
      fontWeight: 500
    },

    container: {
      display: 'flex',
      flex: 1,

      [theme.breakpoints.up('lg')]: {
        // marginLeft: theme.spacing(2),
        marginTop: theme.spacing(0.5)
      },
      [theme.breakpoints.down(1283)]: {
        marginLeft: 0
      }
    },

    rightMenu: {
      marginLeft: 'auto'
    }
  })
)

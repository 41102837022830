import React from 'react'
import { useDisclosure, UserMenuItemProps } from '@plandi/common'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import Link from 'next/link'
import { useRouter } from 'next/router'
import clsx from 'clsx'
import Collapse from '@material-ui/core/Collapse'
import List from '@material-ui/core/List'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'

import { useLayoutHeaderUserMenuItemStyles } from './layout-header-user-menu-item.styles'

export const LayoutHeaderUserMenuItem: React.FC<
  UserMenuItemProps & { className?: string } & {
    style?: React.CSSProperties
  }
> = (props) => {
  const classes = useLayoutHeaderUserMenuItemStyles()
  const router = useRouter()
  const { isOpen, onToggle } = useDisclosure()
  const expandIcon = isOpen ? <ExpandLess /> : <ExpandMore />
  const isVisibleExpandIcon = props.submenu && props.submenu.length > 0

  return (
    <>
      <Link href={props.link ?? ''} passHref>
        <ListItem
          divider={props.isBreak}
          button
          component={props.link ? 'a' : 'li'}
          disabled={!props.link && !isVisibleExpandIcon}
          onClick={onToggle}
          className={clsx(props.className, classes.link, {
            [classes.activeLinkMenu]: router.pathname === props.link
          })}
        >
          {props.icon && (
            <>
              <ListItemIcon>{props.icon}</ListItemIcon>
              <ListItemText
                primary={props.title}
                className={classes.tabTitle}
              />
            </>
          )}
          {!props.icon && <ListItemText primary={props.title} />}
          {props.secondaryContent}
          {isVisibleExpandIcon && expandIcon}
        </ListItem>
      </Link>
      {props.submenu && (
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {props.submenu.map((item) => (
              <LayoutHeaderUserMenuItem
                key={item.title}
                {...item}
                className={classes.nested}
              />
            ))}
          </List>
        </Collapse>
      )}
    </>
  )
}

import { createStyles, makeStyles } from '@material-ui/core'

export const useMessagesTabContentStyles = makeStyles((theme) =>
  createStyles({
    menuEmpty: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: 100,
      paddingBottom: 100
    },

    bottomNav: {
      borderTop: `1px solid ${theme.palette.text.disabled}`,
      paddingTop: theme.spacing(1),
      paddingBottom: theme.spacing(1)
    },

    tab: {
      maxWidth: 'max-content',
      [theme.breakpoints.down('xs')]: {
        fontSize: 12
      }
    },
    loading: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      margin: '142px 0'
    },
    notAllow: {
      margin: 'auto',
      textAlign: 'center',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      maxWidth: 783,
      marginTop: 120,
      marginBottom: 120
    },
    notAllow_text: {
      fontFamily: 'Montserrat',
      fontSize: 24,
      marginBottom: 32
    },
    badge: {
      '&:after': {
        content: '""',
        width: theme.spacing(0.5),
        height: theme.spacing(0.5),
        borderRadius: '100%',
        color: '#fff',
        backgroundColor: theme.palette.primary.main,
        position: 'absolute',
        left: theme.spacing(0.5),
        top: theme.spacing(1.5)
      }
    },

    tabContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%'
    },

    tabs: {
      [theme.breakpoints.down('xs')]: {
        width: '100%'
      }
    },

    sorting: {
      [theme.breakpoints.down('xs')]: {
        display: 'none'
      }
    },

    tabPanelsContainer: {
      marginTop: 49,
      [theme.breakpoints.down('xs')]: {
        marginTop: 38
      }
    },

    tabPanel: {
      padding: 0,
      display: 'flex',
      flexDirection: 'column',
      gap: 5,
      [theme.breakpoints.down('md')]: {
        gap: 9
      }
    },

    buttonRead: {
      marginLeft: 'auto',
      paddingLeft: 20,
      marginRight: theme.spacing(2),
      height: '100%'
    },

    paginationBlock: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: 85
    },

    divideLine: {
      width: '100%',
      margin: '5px 0',
      height: 1,
      background: 'rgba(0, 0, 0, 0.12)',
      [theme.breakpoints.down('md')]: {
        display: 'none'
      },
      '&:last-child': {
        display: 'none'
      }
    }
  })
)

import { combineReducers, configureStore } from '@reduxjs/toolkit'
import type { Action, ThunkAction, ThunkDispatch } from '@reduxjs/toolkit'
import { useDispatch } from 'react-redux'

import { snackbarReducer, config } from '@plandi/common'

export const rootReducer = combineReducers({
  snackbar: snackbarReducer
})

export const store = configureStore({
  reducer: rootReducer,
  devTools: config.isDev
})

export type RootState = ReturnType<typeof store.getState>

export type ThunkResult<R> = ThunkAction<R, RootState, unknown, Action>

export type AppDispatch = typeof store.dispatch

export const useAppDispatch = () => useDispatch<AppDispatch>()

export const useThunkDispatch = () =>
  useDispatch<ThunkDispatch<RootState, unknown, Action>>()

import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useLayoutStyles = makeStyles((theme) =>
  createStyles({
    root: {
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      backgroundColor: 'rgba(248, 248, 248, 1)',
      scrollBehavior: 'smooth'
    },
    redesignedBg: {
      backgroundColor: '#fff'
    },
    footer: {},

    menu: {
      width: 360,

      [theme.breakpoints.down('md')]: {
        width: 300
      }
    },

    logoLink: {
      marginTop: '46px',
      marginLeft: '18px'
    },

    main: {
      display: 'flex',
      flex: 1
    },

    buttonAuth: {
      marginLeft: 10,
      textTransform: 'none',
      fontSize: 16
    },

    avatar: {
      width: theme.spacing(4),
      height: theme.spacing(4),
      backgroundColor: theme.palette.grey.A400
    },

    menuWrapper: {
      display: 'flex',
      alignItems: 'center',
      paddingLeft: 4,
      paddingRight: 0
    },

    iconBtn: {
      '&:focus': {
        backgroundColor: 'transparent'
      }
    },

    iconBtnText: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 12,
      color: '#212121',
      textTransform: 'none',
      '& span': {
        fontSize: 16,
        fontFamily: 'Montserrat',
        fontWeight: 500
      }
    },

    iconBtnTextActive: {
      color: '#E64147'
    },

    userMenu: {
      width: 248,
      boxShadow: '0px 0px 8px 0px rgba(34, 60, 80, 0.2)'
    },

    link: {
      paddingRight: 5,
      fontSize: 16,
      color: 'rgba(0, 0, 0, 0.87)',
      fontFamily: 'Montserrat',
      fontWeight: 500,
      textTransform: 'none'
    },

    linkDivider: {
      height: 23,
      borderRight: '1px solid #9E9E9E',
      marginLeft: 10,
      marginRight: 10
    },

    listItemAvatar: {
      minWidth: 40
    },

    userMenuListItemText: {
      '& span, & span a': {
        fontWeight: 600,
        fontFamily: 'Montserrat',
        fontSize: 14,
        color: '#000',
        textDecoration: 'none'
      },
      '& p': {
        fontWeight: 500,
        fontSize: 11,
        fontFamily: 'Montserrat',
        color: '#757575'
      }
    },

    usernameText: {
      '& span': {
        maxWidth: 180,
        overflow: 'hidden',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis'
      }
    },

    userMenuLinksContainer: {
      padding: '14px 0 0 23px',
      [theme.breakpoints.down(1600)]: {
        maxHeight: 380,
        overflow: 'auto',
        WebkitOverflowScrolling: 'touch',
        WebkitBoxShadow: '0 -3px 5px -5px rgba(34, 60, 80, 0.6) inset',
        MozBoxShadow: '0 -3px 5px -5px rgba(34, 60, 80, 0.6) inset',
        boxShadow: '0 -3px 5px -5px rgba(34, 60, 80, 0.6) inset',
        '&::-webkit-scrollbar': {
          width: 2,
          background: 'transparent'
        },
        '&::-webkit-scrollbar-thumb': {
          background: '#aaa'
        }
      }
    },

    userMenuLinksContainer2: {
      padding: '14px 0 0 23px'
    },

    userMenuLinks: {
      display: 'flex',
      flexDirection: 'column',
      padding: '14px 0 23px 0',
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
    },

    userMenuLinksTop: {
      display: 'flex',
      alignItems: 'center',
      gap: 10
    },

    userLinksBottom: {
      display: 'flex',
      flexDirection: 'column',
      marginTop: 8,
      gap: 10,
      '& a': {
        textDecoration: 'none',
        fontFamily: 'Montserrat',
        fontSize: 12,
        color: '#000',
        border: '1px solid transparent',
        width: 'fit-content',
        transition: '0.3s',
        '&:hover': {
          borderBottom: '1px solid #000'
        }
      }
    },

    userLinksBottomTablet: {
      '& a': {
        fontSize: 14
      }
    },

    userMenuTitle: {
      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontSize: 16,
      color: '#9E9E9E'
    },

    userLinksTitle: {
      fontFamily: 'Montserrat',
      fontWeight: 600,
      fontSize: 14
    },

    userLinksTitleTablet: {
      fontSize: 15
    },

    drawer: {
      '& .MuiDrawer-paper': {
        top: 0,
        boxShadow: '0px 5px 8px -3px rgba(34, 60, 80, 0.2) inset'
      },
      '& .MuiBackdrop-root': {
        top: 0
      }
    },

    tabletMenuList: {
      padding: '50px 20px 85px 0',
      [theme.breakpoints.down('xs')]: {
        padding: '15px 20px 85px 0'
      }
    }
  })
)

import { createStyles, makeStyles } from '@material-ui/core'

export const useAdvertsMessagesStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      padding: '6px 20px 24px 13.35px',
      backgroundColor: '#fff',
      overflow: 'hidden',
      transition: '0.2 ease-on-out'
    },

    opened: {
      height: 'fit-content',
      paddingBottom: 34,
      boxShadow: '0px 0px 4px 0px rgba(34, 60, 80, 0.2)'
    },

    cardTop: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center'
    },

    cardTopLeft: {
      display: 'flex',
      gap: 23,
      [theme.breakpoints.down(768)]: {
        gap: 0
      }
    },

    cardInfo: {
      width: '100%',
      display: 'flex',
      gap: 16,
      alignItems: 'center',
      [theme.breakpoints.down(768)]: {
        flexDirection: 'column',
        gap: 0
      }
    },

    grayText: {
      fontSize: 12,
      letterSpacing: '0.4px',
      lineHeight: '16px',
      color: '#757575',
      [theme.breakpoints.down(768)]: {
        marginRight: 15
      }
    },

    name: {
      width: 'fit-content',
      fontSize: 18,
      letterSpacing: '0.5px',
      lineHeight: '25px',
      fontWeight: 400,
      color: '#212121',
      [theme.breakpoints.down(768)]: {
        marginBottom: 0
      }
    },

    cardTopRight: {
      alignSelf: 'center',
      minWidth: 40,
      [theme.breakpoints.down(768)]: {
        alignSelf: 'flex-start',
        marginTop: 15
      }
    },

    img: {
      alignSelf: 'center',
      marginTop: 5,
      [theme.breakpoints.down(768)]: {
        alignSelf: 'flex-start',
        marginTop: 23
      }
    },

    date: {
      color: '#757575',
      fontSize: 14,
      letterSpacing: '0.25px',
      lineHeight: '20px'
    },

    status: {
      fontSize: 12,
      color: '#212121',
      lineHeight: '16px',
      letterSpacing: '0.4px'
    },

    declinedStatus: {},

    cardBody: {},

    text: {},

    buttons: {
      marginTop: 20,
      display: 'flex',
      justifyContent: 'flex-end',
      gap: 40
    }
  })
)

import React, { FC, useState } from 'react'
import { TabContext, TabList, TabPanel, Pagination } from '@material-ui/lab'
import clsx from 'clsx'
import {
  Button,
  CircularProgress,
  Grid,
  MenuItem,
  Tab,
  TextField,
  useMediaQuery,
  useTheme
} from '@material-ui/core'

import { useMessagesTabContentStyles } from './mesages-tab-content.styles'
import {
  MESSAGES_SERVICE_TYPE,
  MESSAGES_SORT_OPTIONS,
  getMessageServiceTypeOptions
} from '../messages.constants'

import {
  sorting,
  useFilterBySort,
  SORTING_NEW,
  useTranslation
} from '@plandi/common'
import { useRouter } from 'next/router'
import * as queryString from 'querystring'
import { ShopMessageItem } from '../shop-message-item'
import { useQueryAdvertsMessages, useQueryShopMessages } from '../queries'
import { AdvertsMessageItem } from '../adverts-message-item'

type Props = {
  className?: string
  emptyMessage?: string
}

export const MessagesTabContent: FC<Props> = (props) => {
  const [serviceType, setServiceType] = useState(MESSAGES_SERVICE_TYPE.ADVERTS)
  const { data: translate } = useTranslation()
  const {
    data: advertsMessagesF,
    isLoading: advertsMessagesFLoading,
    isError: advertsMessagesFError
  } = useQueryAdvertsMessages()
  const {
    data: shopMessages,
    isLoading: shopMessagesLoading,
    isError: shopMessagesError
  } = useQueryShopMessages()

  const theme = useTheme()
  const isPhoneScreen = useMediaQuery(theme.breakpoints.down('xs'))
  const classes = useMessagesTabContentStyles()

  const router = useRouter()
  const { sort, setSorting } = useFilterBySort()
  const pageTotal =
    serviceType === MESSAGES_SERVICE_TYPE.ADVERTS
      ? advertsMessagesF?.pageTotal
      : shopMessages?.pageTotal

  const handlerMorePage = (
    event: React.ChangeEvent<unknown>,
    pageValue: number
  ) => {
    const objQuery = queryString.parse(router.asPath.split(/\?/)[1])
    router.push(
      {
        pathname: '/messages',
        query: { ...objQuery, page: pageValue }
      },
      undefined,
      { scroll: true }
    )
  }

  const notAllow = () => {
    return (
      <div className={classes.notAllow}>
        <div className={classes.notAllow_text}>У вас пока нет сообщений</div>
        <Button
          variant="contained"
          color="primary"
          onClick={() => router.push('/')}
        >
          {translate?.messages?.button}
        </Button>
      </div>
    )
  }

  return (
    <TabContext value={serviceType}>
      <div className={clsx(classes.tabContainer, props.className)}>
        <TabList
          textColor="secondary"
          onChange={(_, value) => setServiceType(value)}
          className={classes.tabs}
        >
          {getMessageServiceTypeOptions(translate).map(([key, value]: any) => {
            return (
              <Tab
                key={key}
                label={value}
                value={key}
                className={clsx(classes.tab)}
              />
            )
          })}
        </TabList>
        <TextField
          value={sort || SORTING_NEW}
          select
          onChange={(e) => setSorting(e.target.value)}
          className={classes.sorting}
          size="small"
        >
          {MESSAGES_SORT_OPTIONS.map((item, idx) => (
            <MenuItem key={idx} value={item}>
              {sorting(translate)[item]}
            </MenuItem>
          ))}
        </TextField>
      </div>
      <div className={classes.tabPanelsContainer}>
        <TabPanel
          value={MESSAGES_SERVICE_TYPE.ADVERTS}
          className={classes.tabPanel}
        >
          {advertsMessagesFError || advertsMessagesF?.chats.length === 0 ? (
            notAllow()
          ) : advertsMessagesFLoading ? (
            <div className={classes.loading}>
              <CircularProgress size={isPhoneScreen ? 40 : 60} />
            </div>
          ) : (
            advertsMessagesF?.chats.map((message) => (
              <AdvertsMessageItem
                {...message}
                key={message.id}
                translate={translate}
              />
            ))
          )}
        </TabPanel>
        <TabPanel
          value={MESSAGES_SERVICE_TYPE.SHOP}
          className={classes.tabPanel}
        >
          {shopMessagesError || shopMessages?.chats.length === 0 ? (
            notAllow()
          ) : shopMessagesLoading ? (
            <div className={classes.loading}>
              <CircularProgress size={isPhoneScreen ? 40 : 60} />
            </div>
          ) : (
            shopMessages?.chats.map((message) => (
              <>
                <ShopMessageItem key={message.id} {...message} />
                <span className={classes.divideLine} />
              </>
            ))
          )}
        </TabPanel>
      </div>
      {pageTotal !== 0 && !advertsMessagesFLoading && !shopMessagesLoading && (
        <Grid item xs={12}>
          <div className={classes.paginationBlock}>
            <Pagination
              count={pageTotal}
              onChange={handlerMorePage}
              showFirstButton
              showLastButton
              size={isPhoneScreen ? 'small' : 'medium'}
              page={Number(router.query.page || 1)}
            />
          </div>
        </Grid>
      )}
    </TabContext>
  )
}

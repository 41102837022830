import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useLayoutHeaderStyles = makeStyles((theme) =>
  createStyles({
    root: {
      width: '100%',
      zIndex: theme.zIndex.drawer + 1,
      backgroundColor: 'white',
      height: 64
    },

    toolbar: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      padding: '3px 79px 0',

      [theme.breakpoints.down('sm')]: {
        padding: '3px 16px 0',
        '&.MuiToolbar-regular': {
          minHeight: 64
        }
      }
    },

    headerLeft: {
      display: 'flex',
      alignItems: 'center'
    },

    title: {
      fontWeight: 600,
      display: 'none',
      cursor: 'pointer',
      [theme.breakpoints.up(960)]: {
        display: 'block'
      }
    },

    lang: {
      display: 'flex',
      alignItems: 'center',
      marginTop: 4
    },

    rect: {
      height: 4,
      width: 4,
      backgroundColor: 'currentColor',
      display: 'none',
      borderRadius: '50%',
      margin: '0 8px 0 5px',

      [theme.breakpoints.up(960)]: {
        display: 'block'
      }
    },

    btnRu: {
      fontFamily: 'Montserrat',
      fontSize: 16,
      textTransform: 'none',
      minWidth: 40,
      minHeight: 30,
      marginLeft: 30,
      [theme.breakpoints.down(1390)]: {
        marginLeft: 5
      }
    },

    btnEng: {
      fontFamily: 'Montserrat',
      fontSize: 16,
      textTransform: 'none',
      minWidth: 50,
      minHeight: 30,
      marginRight: 30,
      [theme.breakpoints.down(1390)]: {
        marginRight: 5
      }
    },

    linkDivider: {
      height: 23,
      borderRight: '1px solid #9E9E9E'
    },

    usermenu: {
      display: 'flex',
      alignItems: 'center',
      ['@media (min-width: 1440px)']: {
        gap: 36
      },
      ['@media (max-width: 1440px)']: {
        marginLeft: 'auto',
        marginRight: 20
      }
    },

    dropdown: {
      position: 'relative'
    },

    headerLink: {
      textTransform: 'none',
      fontFamily: 'Montserrat',
      fontSize: 16,
      fontWeight: 500
    },

    opened: {
      color: 'red'
    },

    dropdownItems: {
      position: 'absolute',
      top: 35,
      left: 0,
      padding: '5px 14px',
      background: '#FFFFFF',
      boxShadow: '0px 4px 20px rgba(0, 0, 0, 0.25)',
      borderRadius: 6
    },

    dropdownItem: {
      padding: '10px',
      minWidth: 210,
      borderBottom: '1px solid rgba(0, 0, 0, 0.1)'
    },

    dropdownLink: {
      display: 'inline-block',
      width: '100%',
      textDecoration: 'none',
      cursor: 'pointer',
      color: '#000'
    },

    lastItem: {
      borderBottom: 'none'
    },

    headerRight: {
      display: 'flex',
      alignItems: 'center'
    },

    alarmIcon: {
      fontSize: 22
    },

    mobileMenu: {
      position: 'absolute',
      top: 92,
      left: 0,
      width: '100%',
      background: '#fff',
      padding: '20px 18px 30px 18px',
      boxShadow: '0px 5px 8px -3px rgba(34, 60, 80, 0.2) inset'
    },

    mobileMenuButton: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      width: '100%',
      textTransform: 'none',
      padding: '10px 4px',
      fontWeight: 500,
      fontFamily: 'Montserrat, serif',
      fontSize: 18
    },

    subMobileMenu: {
      display: 'flex',
      flexDirection: 'column',
      paddingBottom: 5
    },

    mobileMenuSubButton: {
      fontSize: 12,
      textTransform: 'none',
      fontWeight: 500,
      maxWidth: 165,
      fontFamily: 'Montserrat',
      textDecoration: 'none',
      marginLeft: 6,
      padding: '3px 9px 6px 0',
      color: '#000',
      borderBottom: '1px solid rgba(0, 0, 0, 0.1);',
      '&:last-child': {
        borderBottom: 'none'
      }
    },

    tabletMenuBtn: {
      display: 'flex',
      justifyContent: 'space-between',
      gap: 12
    },

    tabletMenuItem: {
      display: 'flex',
      flexDirection: 'column',
      gap: 8
    },

    tabletMenuSubItem: {
      display: 'flex',
      flexDirection: 'column',
      padding: '5px 20px',
      color: '#000',
      textDecoration: 'none',
      cursor: 'pointer'
    },

    headerTitle: {
      fontSize: 20,
      fontWeight: 600,
      lineHeight: '24px',
      color: '#404041',
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },

    noGap: {
      gap: 0
    },

    favoritesMenu: {
      marginTop: 45
    },

    favoritesItem: {
      fontSize: 14,
      padding: '12px 110px 12px 22px'
    },

    icon: {
      [theme.breakpoints.down('sm')]: {
        display: 'none'
      }
    },

    loginBtn: {
      marginRight: 25
    }
  })
)

import { AxiosError } from 'axios'
import { UseMutationOptions, useMutation, useQueryClient } from 'react-query'
import {
  pushDangerNotification,
  pushSuccessNotification,
  useLocale
} from '@plandi/common'
import { useRouter } from 'next/router'

import { useThunkDispatch } from 'src/store'
import { messagesApi } from './messages.api'
import { messageSearchOptions, sortOrder } from './messages.constants'
import { components } from '@plandi/common/src/generated'

export const useMutationDeleteChatSpecialist = (
  chat: components['schemas']['SpecialistMessageResource'],
  options?: UseMutationOptions<unknown, AxiosError, number>
) => {
  const dispatch = useThunkDispatch()
  const queryClient = useQueryClient()
  const locale = useLocale()
  const router = useRouter()

  const params: messageSearchOptions = {
    sort: (router.query.sort as sortOrder) ?? sortOrder.DESCEND,
    page: router.query.page ? parseInt(router.query.page as string) : 1,
    perPage: 10,
    keyword: (router.query.keyword as string) ?? ''
  }

  const mutation = useMutation(
    (id) => messagesApi.deleteChatSpecialists(id, locale),
    {
      ...options,
      onSuccess: (experiences, variables, context) => {
        dispatch(
          pushSuccessNotification(
            locale === 'ru'
              ? `Чат c ${chat.name} удалён`
              : `Chat with ${chat.name} has been deleted`
          )
        )
        queryClient.invalidateQueries(['SPECIALIST_CHATS', params, locale])
        options?.onSuccess?.(experiences, variables, context)
      }
    }
  )

  return mutation
}

export const useMutationDeleteChatCompamies = (
  chat: components['schemas']['CompanyMessageResource'],
  options?: UseMutationOptions<unknown, AxiosError, number>
) => {
  const dispatch = useThunkDispatch()
  const queryClient = useQueryClient()
  const locale = useLocale()
  const router = useRouter()

  const params: messageSearchOptions = {
    sort: (router.query.sort as sortOrder) ?? sortOrder.DESCEND,
    page: router.query.page ? parseInt(router.query.page as string) : 1,
    perPage: 10,
    keyword: (router.query.keyword as string) ?? ''
  }

  const mutation = useMutation(
    (id) => messagesApi.deleteChatCompanies(id, locale),
    {
      ...options,
      onSuccess: (experiences, variables, context) => {
        dispatch(
          pushSuccessNotification(
            locale === 'ru'
              ? `Чат c ${chat.name} удалён`
              : `Chat with ${chat.name} has been deleted`
          )
        )
        queryClient.invalidateQueries(['COMPANY_CHATS', params, locale])
        options?.onSuccess?.(experiences, variables, context)
      }
    }
  )

  return mutation
}

export const useMutationDeleteChatPlants = (
  chat: components['schemas']['FactoryMessageResource'],
  options?: UseMutationOptions<unknown, AxiosError, number>
) => {
  const dispatch = useThunkDispatch()
  const queryClient = useQueryClient()
  const locale = useLocale()
  const router = useRouter()

  const params: messageSearchOptions = {
    sort: (router.query.sort as sortOrder) ?? sortOrder.DESCEND,
    page: router.query.page ? parseInt(router.query.page as string) : 1,
    perPage: 10,
    keyword: (router.query.keyword as string) ?? ''
  }

  const mutation = useMutation(
    (id) => messagesApi.deleteChatPlants(id, locale),
    {
      ...options,
      onSuccess: (experiences, variables, context) => {
        dispatch(
          pushSuccessNotification(
            locale === 'ru'
              ? `Чат c ${chat.name} удалён`
              : `Chat with ${chat.name} has been deleted`
          )
        )
        queryClient.invalidateQueries(['FACTORIES_CHATS', params, locale])
        options?.onSuccess?.(experiences, variables, context)
      }
    }
  )

  return mutation
}

export const useMutationDeleteChatProjects = (
  chat: components['schemas']['MessagesProjectResource'],
  options?: UseMutationOptions<unknown, AxiosError, number>
) => {
  const dispatch = useThunkDispatch()
  const queryClient = useQueryClient()
  const locale = useLocale()
  const router = useRouter()

  const params: messageSearchOptions = {
    sort: (router.query.sort as sortOrder) ?? sortOrder.DESCEND,
    page: router.query.page ? parseInt(router.query.page as string) : 1,
    perPage: 10,
    keyword: (router.query.keyword as string) ?? ''
  }

  const mutation = useMutation(
    (id) => messagesApi.deleteChatProjects(id, locale),
    {
      ...options,
      onSuccess: (experiences, variables, context) => {
        dispatch(
          pushSuccessNotification(
            locale === 'ru'
              ? `Чат по проекту "${chat.project.name}" удалён`
              : `Chat about the "${chat.project.name}" project has been deleted`
          )
        )
        queryClient.invalidateQueries(['PROJECT_MESSAGES', params, locale])
        options?.onSuccess?.(experiences, variables, context)
      }
    }
  )

  return mutation
}

export const useMutationDeleteChatModels = (
  chat: components['schemas']['MessagesModelsResource'],
  options?: UseMutationOptions<unknown, AxiosError, number>
) => {
  const dispatch = useThunkDispatch()
  const queryClient = useQueryClient()
  const locale = useLocale()
  const router = useRouter()

  const params: messageSearchOptions = {
    sort: (router.query.sort as sortOrder) ?? sortOrder.DESCEND,
    page: router.query.page ? parseInt(router.query.page as string) : 1,
    perPage: 10,
    keyword: (router.query.keyword as string) ?? ''
  }

  const mutation = useMutation(
    (id) => messagesApi.deleteChatModels(id, locale),
    {
      ...options,
      onSuccess: (experiences, variables, context) => {
        dispatch(
          pushSuccessNotification(
            locale === 'ru'
              ? `Чат по по модели "${chat.model.name}" удалён`
              : `Chat about the "${chat.model.name}" model has been deleted`
          )
        )
        queryClient.invalidateQueries(['MODELS_MESSAGES', params, locale])
        options?.onSuccess?.(experiences, variables, context)
      }
    }
  )

  return mutation
}

export const useMutationCloseChatSpecialist = (
  id: number,
  chat: components['schemas']['SpecialistMessageResource'],
  options?: UseMutationOptions<
    unknown,
    AxiosError,
    { status: 'active' | 'decline' }
  >
) => {
  const dispatch = useThunkDispatch()
  const queryClient = useQueryClient()
  const locale = useLocale()
  const router = useRouter()

  const params: messageSearchOptions = {
    sort: (router.query.sort as sortOrder) ?? sortOrder.DESCEND,
    page: router.query.page ? parseInt(router.query.page as string) : 1,
    perPage: 10,
    keyword: (router.query.keyword as string) ?? ''
  }

  const mutation = useMutation(
    (requestBody) => messagesApi.closeChatSpecialists(requestBody, id, locale),
    {
      ...options,
      onSuccess: (experiences, variables, context) => {
        dispatch(
          pushSuccessNotification(
            locale === 'ru'
              ? `Чат c ${chat.name} ${
                  chat?.status === 'active' ? 'заблокирован' : 'разблокирован'
                }`
              : `Chat with ${chat.name} has been ${
                  chat?.status === 'active' ? 'blocked' : 'unblocked'
                }`
          )
        )
        queryClient.invalidateQueries(['SPECIALIST_CHATS', params, locale])
        options?.onSuccess?.(experiences, variables, context)
      },
      onError(error, variables, context) {
        options?.onError?.(error, variables, context)
        dispatch(
          pushDangerNotification(
            locale === 'ru'
              ? 'Ошибка, попробуйте позднее'
              : ' Error, try again later'
          )
        )
      }
    }
  )

  return mutation
}

export const useMutationCloseChatCompamies = (
  id: number,
  chat: components['schemas']['CompanyMessageResource'],
  options?: UseMutationOptions<
    unknown,
    AxiosError,
    { status: 'active' | 'decline' }
  >
) => {
  const dispatch = useThunkDispatch()
  const queryClient = useQueryClient()
  const locale = useLocale()
  const router = useRouter()

  const params: messageSearchOptions = {
    sort: (router.query.sort as sortOrder) ?? sortOrder.DESCEND,
    page: router.query.page ? parseInt(router.query.page as string) : 1,
    perPage: 10,
    keyword: (router.query.keyword as string) ?? ''
  }

  const mutation = useMutation(
    (requestBody) => messagesApi.closeChatCompanies(requestBody, id, locale),
    {
      ...options,
      onSuccess: (experiences, variables, context) => {
        dispatch(
          pushSuccessNotification(
            locale === 'ru'
              ? `Чат c ${chat.name} ${
                  chat?.status === 'active' ? 'заблокирован' : 'разблокирован'
                }`
              : `Chat with ${chat.name} has been ${
                  chat?.status === 'active' ? 'blocked' : 'unblocked'
                }`
          )
        )
        queryClient.invalidateQueries(['COMPANY_CHATS', params, locale])
        options?.onSuccess?.(experiences, variables, context)
      }
    }
  )

  return mutation
}

export const useMutationCloseChatPlants = (
  id: number,
  chat: components['schemas']['FactoryMessageResource'],
  options?: UseMutationOptions<
    unknown,
    AxiosError,
    { status: 'active' | 'decline' }
  >
) => {
  const dispatch = useThunkDispatch()
  const queryClient = useQueryClient()
  const locale = useLocale()
  const router = useRouter()

  const params: messageSearchOptions = {
    sort: (router.query.sort as sortOrder) ?? sortOrder.DESCEND,
    page: router.query.page ? parseInt(router.query.page as string) : 1,
    perPage: 10,
    keyword: (router.query.keyword as string) ?? ''
  }

  const mutation = useMutation(
    (requestBody) => messagesApi.closeChatPlants(requestBody, id, locale),
    {
      ...options,
      onSuccess: (experiences, variables, context) => {
        dispatch(
          pushSuccessNotification(
            locale === 'ru'
              ? `Чат c ${chat.name} ${
                  chat?.status === 'active' ? 'заблокирован' : 'разблокирован'
                }`
              : `Chat with ${chat.name} has been ${
                  chat?.status === 'active' ? 'blocked' : 'unblocked'
                }`
          )
        )
        queryClient.invalidateQueries(['FACTORIES_CHATS', params, locale])
        options?.onSuccess?.(experiences, variables, context)
      }
    }
  )

  return mutation
}

export const useMutationCloseChatProjects = (
  id: number,
  chat: components['schemas']['MessagesProjectResource'],
  options?: UseMutationOptions<
    unknown,
    AxiosError,
    { status: 'active' | 'decline' }
  >
) => {
  const dispatch = useThunkDispatch()
  const queryClient = useQueryClient()
  const locale = useLocale()
  const router = useRouter()

  const params: messageSearchOptions = {
    sort: (router.query.sort as sortOrder) ?? sortOrder.DESCEND,
    page: router.query.page ? parseInt(router.query.page as string) : 1,
    perPage: 10,
    keyword: (router.query.keyword as string) ?? ''
  }

  const mutation = useMutation(
    (requestBody) => messagesApi.closeChatProjects(requestBody, id, locale),
    {
      ...options,
      onSuccess: (experiences, variables, context) => {
        dispatch(
          pushSuccessNotification(
            locale === 'ru'
              ? `Чат по проекту "${chat.project.name}" ${
                  chat?.status === 'active' ? 'заблокирован' : 'разблокирован'
                }`
              : `Chat about the "${chat.project.name}" project has been ${
                  chat?.status === 'active' ? 'blocked' : 'unblocked'
                }`
          )
        )
        queryClient.invalidateQueries(['PROJECT_MESSAGES', params, locale])
        options?.onSuccess?.(experiences, variables, context)
      }
    }
  )

  return mutation
}

export const useMutationCloseChatModels = (
  id: number,
  chat: components['schemas']['MessagesModelsResource'],
  options?: UseMutationOptions<
    unknown,
    AxiosError,
    { status: 'active' | 'decline' }
  >
) => {
  const dispatch = useThunkDispatch()
  const queryClient = useQueryClient()
  const locale = useLocale()
  const router = useRouter()

  const params: messageSearchOptions = {
    sort: (router.query.sort as sortOrder) ?? sortOrder.DESCEND,
    page: router.query.page ? parseInt(router.query.page as string) : 1,
    perPage: 10,
    keyword: (router.query.keyword as string) ?? ''
  }

  const mutation = useMutation(
    (requestBody) => messagesApi.closeChatModels(requestBody, id, locale),
    {
      ...options,
      onSuccess: (experiences, variables, context) => {
        dispatch(
          pushSuccessNotification(
            locale === 'ru'
              ? `Чат по по модели "${chat.model.name}" ${
                  chat?.status === 'active' ? 'заблокирован' : 'разблокирован'
                }`
              : `Chat about the "${chat.model.name}" model has been ${
                  chat?.status === 'active' ? 'blocked' : 'unblocked'
                }`
          )
        )
        queryClient.invalidateQueries(['MODELS_MESSAGES', params, locale])
        options?.onSuccess?.(experiences, variables, context)
      }
    }
  )

  return mutation
}

import React, { FC, useState } from 'react'
import Image from 'next/image'
import { Button, IconButton, useMediaQuery, useTheme } from '@material-ui/core'
import { useRouter } from 'next/router'

import { useAdvertsMessagesStyles } from './adverts-message-item.styles'
import { components } from '@plandi/common/src/generated'
import { SERVICES_URL } from '@plandi/common'

type Props = components['schemas']['ChatsAdvert']

export const AdvertsMessageItem: FC<Props> = ({
  lastMessage,
  specialist,
  translate
}) => {
  const [isDropdownOpened, setIsDropdownOpened] = useState(false)
  const classes = useAdvertsMessagesStyles()
  const router = useRouter()

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down(768))

  let message = lastMessage.content

  if (!isDropdownOpened && lastMessage.content?.length > 142) {
    message = lastMessage.content?.slice(0, 142).trim() + '...'
  }

  const slug_name = specialist.slug_name

  const handlePush = () => {
    const api =
      SERVICES_URL.job +
      `/profile-specialist/${slug_name}/${specialist.id}/chat`
    router.push(api)
  }

  const specializationsSpecialistMain =
    specialist.mainActivitySpecializations?.map((item) => item.name)
  const skills = specialist.skills?.join(' ')

  return (
    <div className={classes.root}>
      <div className={classes.cardTop}>
        <div className={classes.cardTopLeft}>
          <div className={classes.img}>
            <Image
              width={18.75}
              height={20}
              alt=""
              src="/assets/icons/advert-message-people.svg"
            />
          </div>
          <div className={classes.cardInfo}>
            <h3 className={classes.name}>{specialist?.name}</h3>
            {isMobile ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center',
                  width: '100%'
                }}
              >
                <p className={classes.grayText}>
                  {specialist?.location?.country?.name ?? 'Страна не указана'}
                </p>
                <p className={classes.grayText}>
                  {specialist?.location?.city?.name ?? 'Город не указан'}
                </p>
                <p className={classes.grayText}>
                  {specializationsSpecialistMain}
                </p>
              </div>
            ) : (
              <>
                <p className={classes.grayText}>
                  {specialist?.location?.country?.name ?? 'Страна не указана'}
                </p>
                <p className={classes.grayText}>
                  {specialist?.location?.city?.name ?? 'Город не указан'}
                </p>
                <p className={classes.grayText}>
                  {specializationsSpecialistMain}
                </p>
                <p className={classes.grayText}>{skills}</p>
              </>
            )}
          </div>
        </div>
        <div className={classes.cardTopRight}>
          <IconButton onClick={() => setIsDropdownOpened((prev) => !prev)}>
            <Image
              width={16}
              height={8}
              alt=""
              src={`/assets/icons/chevron${
                isDropdownOpened ? '-top' : '-bottom'
              }.svg`}
            />
          </IconButton>
        </div>
      </div>
      <div className={classes.cardBody}>
        <p className={classes.text}>{message}</p>
        {isDropdownOpened && (
          <div className={classes.buttons}>
            <Button variant="contained" color="primary" onClick={handlePush}>
              {translate?.menu.messages}
            </Button>
          </div>
        )}
      </div>
    </div>
  )
}

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react'
// import Link from 'next/link'
import {
  getAuthSignInUrl,
  // getServiceURL,
  SERVICES_URL,
  useDisclosure,
  useLocale,
  useMutationLogout,
  useQueryUser,
  useTranslation
} from '@plandi/common'
import Skeleton from '@material-ui/lab/Skeleton'
import Avatar from '@material-ui/core/Avatar'
import Button from '@material-ui/core/Button'
import Popper from '@material-ui/core/Popper'
import Image from 'next/image'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import ListItemAvatar from '@material-ui/core/ListItemAvatar'
import KeyboardArrowTopIcon from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import {
  EmailOutlined,
  SettingsOutlined,
  StarOutline,
  AccountBoxOutlined
} from '@material-ui/icons'
import clsx from 'clsx'
import { useMediaQuery, useTheme, Link } from '@material-ui/core'
import { useRouter } from 'next/router'
import { useLayoutStyles } from '../layout.styles'
import { DrawerUserMenu } from '../drawer-user-menu'
import { TranslationDictionary } from '@plandi/common/src/utils/dictionary.types'
import Badge from '@material-ui/core/Badge'
import { useUnreadMessageQuery } from '../../../messages/common'

export const userMenuMain = (
  translate: TranslationDictionary['data'],
  locale: string
) => {
  const url = SERVICES_URL

  return [
    {
      title: translate?.['market']?.['title'] ?? 'Проектный маркет',
      link:
        locale === 'ru'
          ? `${url.shop}/ru${'/models'}`
          : `${url.shop}${'/models'}`
    },
    {
      title: translate?.['job']?.['title2'] ?? 'Фриланс и работа',
      link:
        locale === 'ru'
          ? `${url.job}/ru${'/applicants'}`
          : `${url.job}${'/applicants'}`
    },
    {
      title: translate?.office?.title ?? '',
      link: `https://${
        process.env.NEXT_PUBLIC_NODE_ENV === 'stage' ? 'stage-office' : 'office'
      }.plandi.io${locale === 'ru' ? '/ru' : ''}`
    },
    {
      title: translate?.blog ?? '',
      link: locale === 'ru' ? `${url.blog}/ru/articles` : `${url.blog}/articles`
    }
  ]
}

type UserMenuProps = {
  onLogoutSuccess?: () => void
  redirectTo?: string
  loginBtnClass?: string
}

export const LayoutHeaderUserMenu: React.FC<UserMenuProps> = (props) => {
  const classes = useLayoutStyles()
  const buttonRef = useRef<Element>()
  const router = useRouter()
  const locale = useLocale()
  const url = SERVICES_URL
  const { data: translation } = useTranslation()

  const { isOpen, onClose, onToggle } = useDisclosure()

  const { data: user, isLoading } = useQueryUser({
    notifyOnChangeProps: ['data'],
    staleTime: 1000 * 60
  })

  const mutation = useMutationLogout({
    onSuccess: () => {
      router.push(locale === 'ru' ? '/ru' : '/')
      props.onLogoutSuccess?.()
    }
  })

  const handleLogout = () => {
    mutation.mutate()
  }

  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.up(960))
  const isLg = useMediaQuery(theme.breakpoints.up(1280))

  const profileUrl =
    locale === 'ru' ? `${url.main}/ru${'/profile'}` : `${url.main}/profile`
  const messageUrl =
    locale === 'ru' ? `${url.main}/ru${'/messages'}` : `${url.main}/messages`
  const favoriteUrl =
    locale === 'ru' ? `${url.main}/ru${'/favorites'}` : `${url.main}/favorites`
  const anketsUrl =
    locale === 'ru'
      ? `${url.job}/ru${'/questionnaires'}`
      : `${url.job}/questionnaires`
  const { data: unreadedMessages } = useUnreadMessageQuery()

  const authHref = getAuthSignInUrl(
    locale,
    locale === 'ru' ? `${url.main}/ru` : url.main
  )

  if (isLoading) {
    return (
      <Skeleton variant="circle">
        <Avatar className={classes.avatar} />
      </Skeleton>
    )
  }

  if (!user)
    return (
      <>
        <Button
          href={
            locale === 'ru'
              ? `${url.shop}/ru/subscriptions`
              : url.shop + '/subscriptions'
          }
          size="small"
          className={classes.link}
        >
          {translation?.['menu']?.['subscriptions'] ?? 'menu.subscription'}
        </Button>
        <div className={classes.linkDivider} />
        <Button
          variant="contained"
          color="secondary"
          className={clsx(classes.buttonAuth, props.loginBtnClass)}
          size="small"
          href={authHref}
        >
          {translation?.['menu']?.['login'] ?? 'menu.login'}
        </Button>
      </>
    )

  return (
    <>
      <Button
        onClick={onToggle}
        role="button"
        tabIndex={0}
        innerRef={buttonRef}
        className={classes.menuWrapper}
      >
        <Avatar className={classes.avatar} src={user.avatarUrl}>
          {user?.name?.slice(0, 1)}
        </Avatar>
        {isTablet && (
          <div
            className={clsx(classes.iconBtnText, {
              [classes.iconBtnTextActive]: isOpen
            })}
          >
            <span> {translation?.account ?? 'Account'}</span>
            {isOpen ? (
              <KeyboardArrowTopIcon style={{ marginTop: 2 }} />
            ) : (
              <KeyboardArrowDownIcon style={{ marginTop: 2 }} />
            )}
          </div>
        )}
      </Button>
      {isLg ? (
        <Popper
          className={classes.userMenu}
          open={isOpen}
          transition
          style={{ top: 50, left: 'auto' }}
          disablePortal
          placement="bottom-end"
        >
          <Paper>
            <ClickAwayListener onClickAway={onClose}>
              <List>
                <ListItem button component="a" href={profileUrl}>
                  <ListItemAvatar className={classes.listItemAvatar}>
                    <Avatar className={classes.avatar} src={user.avatarUrl}>
                      {user?.name?.slice(0, 1)}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText
                    className={clsx(
                      classes.userMenuListItemText,
                      classes.usernameText
                    )}
                    primary={user?.name}
                    secondary={user?.email}
                  />
                </ListItem>
                <div className={classes.userMenuLinksContainer}>
                  <span className={classes.userMenuTitle}>
                    {translation?.['my-profile']}:
                  </span>

                  <div className={classes.userMenuLinks}>
                    <div className={classes.userMenuLinksTop}>
                      <Image
                        src="/assets/new-landing/header/advert.svg"
                        layout="fixed"
                        width="16"
                        height="16"
                        alt=""
                      />
                      <span className={classes.userLinksTitle}>
                        {translation?.job?.title ?? 'Фриланс и работа'}
                      </span>
                    </div>
                    <div className={classes.userLinksBottom}>
                      <Link
                        underline="none"
                        href={
                          locale === 'ru'
                            ? `${url.job}/ru/tenders/me`
                            : url.job + '/tenders/me'
                        }
                      >
                        {translation?.menu?.['my-tenders'] ?? 'menu.my-tenders'}
                      </Link>
                      <Link
                        underline="none"
                        href={
                          locale === 'ru'
                            ? `${url.job}/ru/contract/me`
                            : url.job + '/contract/me'
                        }
                      >
                        {translation?.menu?.['contracts'] ?? 'menu.contracts'}
                      </Link>
                      <Link
                        underline="none"
                        href={
                          locale === 'ru'
                            ? `${url.main}/ru/profile/purchases?type=specialist`
                            : url.main + '/profile/purchases?type=specialist'
                        }
                      >
                        {translation?.['menu']?.['subscriptions'] ?? 'Подписки'}
                      </Link>
                    </div>
                  </div>

                  <div className={classes.userMenuLinks}>
                    <div className={classes.userMenuLinksTop}>
                      <Image
                        src="/assets/new-landing/header/market.svg"
                        layout="fixed"
                        width="16"
                        height="16"
                        alt=""
                      />
                      <span className={classes.userLinksTitle}>
                        {translation?.['market']?.['title'] ?? 'menu.title'}
                      </span>
                    </div>
                    <div className={classes.userLinksBottom}>
                      <Link
                        underline="none"
                        href={
                          locale === 'ru'
                            ? `${url.shop}/ru/projects/me`
                            : url.shop + '/projects/me'
                        }
                      >
                        {translation?.['menu']?.['my-projects'] ??
                          'menu.my-projects'}
                      </Link>
                      <Link
                        underline="none"
                        href={
                          locale === 'ru'
                            ? `${url.shop}/ru` + '/models/me'
                            : url.shop + '/models/me'
                        }
                      >
                        {translation?.['menu']?.['my-models'] ??
                          'menu.my-models'}
                      </Link>
                      <Link
                        underline="none"
                        href={
                          locale === 'ru'
                            ? `${url.shop}/ru/goods/me`
                            : url.shop + '/goods/me'
                        }
                      >
                        {locale === 'en' ? 'My goods' : 'Мои товары'}
                      </Link>
                      <Link
                        underline="none"
                        href={
                          locale === 'ru'
                            ? `${url.main}/ru/profile/purchases`
                            : url.main + '/profile/purchases'
                        }
                      >
                        {translation?.['menu']?.['subscriptions'] ?? 'Подписки'}
                      </Link>
                    </div>
                  </div>

                  <div className={classes.userMenuLinks}>
                    <div className={classes.userMenuLinksTop}>
                      <Image
                        src="/assets/new-landing/header/connected-system.svg"
                        layout="fixed"
                        width="16"
                        alt=""
                        height="16"
                      />
                      <span className={classes.userLinksTitle}>
                        {translation?.['office']?.['title'] ?? 'Офис'}
                      </span>
                    </div>
                    <div className={classes.userLinksBottom}>
                      <Link
                        underline="none"
                        href={
                          locale === 'ru'
                            ? `${url.office}/ru` + '/projects'
                            : url.office + '/projects'
                        }
                      >
                        {translation?.['menu']?.['my-projects']}
                      </Link>
                    </div>
                  </div>

                  <div className={classes.userMenuLinks}>
                    <div className={classes.userMenuLinksTop}>
                      <Image
                        src="/assets/new-landing/header/blog.svg"
                        layout="fixed"
                        width="16"
                        height="16"
                      />
                      <span className={classes.userLinksTitle}>
                        {translation?.blog2?.title}
                      </span>
                    </div>
                    <div className={classes.userLinksBottom}>
                      <Link
                        underline="none"
                        href={
                          locale === 'ru'
                            ? `${url.blog}/ru/articles`
                            : `${url.blog}/articles`
                        }
                      >
                        {translation?.menu?.['my-articles'] ??
                          'menu.my-articles'}
                      </Link>
                    </div>
                  </div>
                </div>
                <ListItem
                  button
                  component="a"
                  // onClick={() => router.push(anketsUrl)}
                  href={anketsUrl}
                >
                  <AccountBoxOutlined style={{ width: 18, height: 18 }} />
                  <ListItemText
                    style={{ marginLeft: 5 }}
                    className={classes.userMenuListItemText}
                  >
                    {translation?.['personal-account']?.['my-ankets'] ??
                      'Мои анкеты'}
                  </ListItemText>
                </ListItem>
                <ListItem button onClick={() => router.push(messageUrl)}>
                  <Badge
                    color="secondary"
                    badgeContent={unreadedMessages?.count}
                    anchorOrigin={{
                      vertical: 'top',
                      horizontal: 'left'
                    }}
                  >
                    <EmailOutlined style={{ width: 18, height: 18 }} />
                  </Badge>
                  <ListItemText
                    style={{ marginLeft: 5 }}
                    className={classes.userMenuListItemText}
                  >
                    {translation?.['menu']?.['messages'] ?? 'Сообщения'}
                  </ListItemText>
                </ListItem>
                <ListItem button onClick={() => router.push(favoriteUrl)}>
                  <StarOutline style={{ width: 18, height: 18 }} />
                  <ListItemText
                    style={{ marginLeft: 5 }}
                    className={classes.userMenuListItemText}
                  >
                    {translation?.['favorites']?.['title'] ?? 'favorites.title'}
                  </ListItemText>
                </ListItem>
                <ListItem button onClick={() => router.push(profileUrl)}>
                  <SettingsOutlined style={{ width: 18, height: 18 }} />
                  <ListItemText
                    style={{ marginLeft: 5 }}
                    className={classes.userMenuListItemText}
                  >
                    {translation?.['menu']?.['settings'] ?? 'Настройки'}
                  </ListItemText>
                </ListItem>
                <ListItem button onClick={handleLogout}>
                  <Image
                    width={13}
                    height={13}
                    layout="fixed"
                    alt=""
                    src="/assets/icons/exit.svg"
                  />
                  <ListItemText
                    style={{ marginLeft: 8 }}
                    className={classes.userMenuListItemText}
                  >
                    {translation?.['menu']?.['logout'] ?? 'Выйти'}
                  </ListItemText>
                </ListItem>
              </List>
            </ClickAwayListener>
          </Paper>
        </Popper>
      ) : (
        <DrawerUserMenu isOpen={isOpen} onClose={onClose} />
      )}
    </>
  )
}

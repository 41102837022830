import React from 'react'

import { useLayoutContentStyles } from './layout-content.styles'

export type ContentProps = {
  children: React.ReactNode
  withSidebar?: boolean
  sidebarWidth?: number
}

export const LayoutContent: React.FC<ContentProps> = (props) => {
  const classes = useLayoutContentStyles()

  return <div className={classes.content}>{props.children}</div>
}

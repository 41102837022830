import { createStyles, makeStyles } from '@material-ui/core/styles'

export const useNewFooterStyles = makeStyles((theme) =>
  createStyles({
    root: {
      backgroundColor: '#272B2D',
      padding: '64px 0',
      [theme.breakpoints.down(900)]: {
        padding: '32px 0'
      }
    },
    wrapper: {
      maxWidth: '1360px',
      padding: 0,
      [theme.breakpoints.down(1440)]: {
        padding: '0 32px'
      }
    },
    copy: {
      fontSize: '14px',
      fontWeight: 400,
      color: '#EEEEEC'
    },
    linksContainer: {
      [theme.breakpoints.down(900)]: {
        display: 'none'
      }
    },
    docLinks: {
      [theme.breakpoints.down(900)]: {
        display: 'none !important'
      }
    },
    linksColumn: {
      display: 'flex',
      flexDirection: 'column',
      gap: '16px'
    },
    link: {
      fontSize: '16px',
      color: '#EEEEEC'
    },
    headLink: {
      fontWeight: 600
    },
    socials: {
      display: 'flex',
      flexDirection: 'row',
      gap: '8px',
      padding: '32px 0 48px',
      '& a>svg': {
        display: 'block !important'
      },
      [theme.breakpoints.down(900)]: {
        padding: '0 0 24px'
      }
    },
    logoContainer: {
      [theme.breakpoints.down(900)]: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      },
      '& svg:first-child': {
        [theme.breakpoints.down(900)]: {
          display: 'none'
        }
      }
    }
  })
)

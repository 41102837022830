import { useQuery } from 'react-query'

import { useFilterBySort, useLocale } from '@plandi/common'
import { messagesApi } from '../messages.api'
import { messagesSortOptions, SHOP_MESSAGES_DATA } from '../messages.constants'

export const useQueryShopMessages = () => {
  const { sort } = useFilterBySort()
  const locale = useLocale()

  const query = useQuery([SHOP_MESSAGES_DATA, sort, locale], () =>
    messagesApi.getShopMessages(locale, sort as messagesSortOptions)
  )

  return query
}

import React from 'react'
import {
  // getServiceURL,
  SERVICES_URL,
  UserMenuItemProps,
  useTranslation,
  useLocale,
  TranslationDictionary
} from '@plandi/common'
import Badge from '@material-ui/core/Badge'
import { ReactComponent as OrderIcon } from '../../../../assets/ordericon.svg'
import { ReactComponent as Contract } from '../../../../assets/contract.svg'
import { ReactComponent as SpecialistIcon } from '../../../../assets/specialists.svg'
import { ReactComponent as CompanyIcon } from '../../../../assets/company.svg'
import { ReactComponent as ResponseIcon } from '../../../../assets/response.svg'
import { ReactComponent as GoodsIcon } from '../../../../assets/package.svg'
import { ReactComponent as ProjectIcon1 } from '../../../../assets/project1.svg'
import { ReactComponent as ProjectIcon2 } from '../../../../assets/project2.svg'
import { ReactComponent as TeamIcon } from '../../../../assets/team.svg'
import { ReactComponent as SettingsIcon } from '../../../../assets/settings.svg'
import { ReactComponent as BoardIcon } from '../../../../assets/board.svg'
import { ReactComponent as ModelsIcon } from '../../../../assets/models.svg'
import { ReactComponent as SubsIcon } from '../../../../assets/subscriptions.svg'

import { LayoutHeaderUserMenuItem } from '../layout-header-user-menu-item'
import { useRouter } from 'next/router'

type LayoutBurgerMenuProps = {
  countNewNotifications?: number
  translate?: TranslationDictionary['data']
  locale: string
}

export const LayoutBurgerMenu: React.FC<LayoutBurgerMenuProps> = ({
  countNewNotifications
}) => {
  const router = useRouter()
  const locale = useLocale()
  const url = SERVICES_URL
  const { data: translation } = useTranslation()
  const redirectUrl = SERVICES_URL.main + router.asPath

  const userMenu: UserMenuItemProps[] = [
    {
      title: translation?.['job']?.title2 ?? 'job.title2',
      submenu: [
        {
          title: translation?.['orders'] ?? 'orders',
          link:
            locale === 'ru'
              ? `${url.job}/ru/applicants`
              : `${url.job}/applicants`,
          icon: <OrderIcon />
        },
        {
          title: translation?.['specialists'] ?? 'specialists',
          link:
            locale === 'ru'
              ? `${url.job}/ru/employers`
              : `${url.job}/employers`,
          icon: <SpecialistIcon />
        },
        {
          title: translation?.companies ?? 'companies',
          link:
            locale === 'ru'
              ? `${url.job}/ru/companies`
              : `${url.job}/companies`,
          icon: <CompanyIcon />
        },
        {
          title: translation?.menu.contracts ?? 'menu.contracts',
          link:
            locale === 'ru'
              ? `${url.job}/ru/contract/me`
              : `${url.job}/factorie/contract/me`,
          icon: <Contract />
        },
        {
          title: translation?.menu?.subscriptions ?? 'menu.subscriptions',
          link:
            locale === 'ru'
              ? `${url.main}/ru/profile/purchases?type=specialist`
              : url.main + '/profile/purchases?type=specialist',
          icon: <SubsIcon />
        },
        {
          title: translation?.feedbacks ?? 'feedbacks',
          link:
            locale === 'ru'
              ? `${url.job}/ru/tenders/feedbacks`
              : url.job + '/tenders/feedbacks',
          icon: <ResponseIcon />
        }
      ]
    },
    {
      title: translation?.office?.title ?? 'office.title',
      submenu: [
        {
          title: translation?.projects ?? 'projects',
          icon: <ProjectIcon1 />,
          submenu: [
            {
              title: translation?.['all-projects'] ?? 'all-projects',
              link:
                locale === 'ru'
                  ? `${url.office}/ru` + '/projects'
                  : url.office + '/projects'
            },
            {
              title: translation?.['project-archive'] ?? 'project-archive'
            }
          ]
        },
        {
          title:
            translation?.['office-site']?.['dashboard'] ??
            'office-site.dashboard',
          link:
            locale === 'ru'
              ? `${url.office}/ru` + '/board'
              : url.office + '/board',
          icon: <BoardIcon />
        },
        {
          title: translation?.['office-site']?.['team'] ?? 'office-site.team',
          link:
            locale === 'ru'
              ? `${url.office}/ru` + '/team'
              : url.office + '/team',
          icon: <TeamIcon />
        },
        {
          title:
            translation?.['office-site']?.['settings'] ??
            'office-site.settings',
          link:
            locale === 'ru'
              ? `${url.office}/ru` + '/notifications-settings'
              : url.office + '/notifications-settings',
          icon: <SettingsIcon />
        }
      ]
    },
    {
      title: translation?.market?.title ?? 'market.title',
      submenu: [
        {
          title: translation?.projects ?? 'projects',
          icon: <ProjectIcon2 />,
          link:
            locale === 'ru' ? `${url.shop}/ru/projects` : url.shop + '/projects'
        },
        {
          title: translation?.models ?? 'models',
          link:
            locale === 'ru' ? `${url.shop}/ru/models` : url.shop + '/models',
          icon: <ModelsIcon />
        },
        {
          title: locale === 'en' ? 'Goods' : 'Товары',
          link: locale === 'ru' ? `${url.shop}/ru/goods` : url.shop + '/goods',
          icon: <GoodsIcon />
        },
        {
          title: translation?.menu?.subscriptions ?? 'menu.subscriptions',
          link:
            locale === 'ru'
              ? `${url.main}/ru/profile/purchases`
              : url.main + '/profile/purchases',
          icon: <SubsIcon />
        }
      ]
    }
  ]

  return (
    <div>
      {userMenu.map((item) => (
        <LayoutHeaderUserMenuItem key={item.title} {...item} />
      ))}
      <LayoutHeaderUserMenuItem
        title={translation?.alerts ?? 'alerts'}
        link={
          locale === 'ru'
            ? `${url.main}/ru/notifications`
            : url.main + '/notifications'
        }
        secondaryContent={
          <Badge
            badgeContent={countNewNotifications ? countNewNotifications : 0}
            color="secondary"
            max={9}
            style={{ marginRight: 10 }}
          />
        }
      />
      <LayoutHeaderUserMenuItem
        title="FAQ"
        link={
          locale === 'ru'
            ? `${url.main}/ru/questions?question=job&redirect=${redirectUrl}`
            : url.main + `/questions?question=job&redirect=${redirectUrl}`
        }
      />
      <LayoutHeaderUserMenuItem
        title={
          typeof translation?.blog === 'string'
            ? translation?.blog
            : translation?.blog?.title ?? 'blog'
        }
        link={
          locale === 'ru' ? `${url.blog}/ru/articles` : url.blog + '/articles'
        }
      />
    </div>
  )
}

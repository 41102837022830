import { makeStyles, createStyles } from '@material-ui/core/styles'

export const useLayoutHeaderUserMenuItemStyles = makeStyles((theme) =>
  createStyles({
    activeLinkMenu: {
      '& *': {
        color: theme.palette.primary.main
      }
    },

    link: {
      '& .MuiTypography-body1': {
        fontWeight: 600,
        fontFamily: 'Montserrat',
        fontSize: 15
      }
    },

    nested: {
      paddingLeft: theme.spacing(2)
    },

    tabTitle: {
      marginLeft: '-25px',
      '& .MuiTypography-body1': {
        fontWeight: 400,
        fontFamily: 'Montserrat',
        fontSize: 14
      }
    },

    divider: {
      position: 'static',
      width: '100%',
      height: '0px',
      left: '0px',
      top: '179px',
      border: '1px solid rgba(0, 0, 0, 0.1)',
      flex: 'none',
      margin: '2px 0px'
    }
  })
)

import React, { FC } from 'react'
import Image from 'next/image'
import { Typography, useMediaQuery, useTheme } from '@material-ui/core'
import Link from 'next/link'

import { useShopMessageItemStyles } from './shop-message-item.styles'
import { components } from '@plandi/common/src/generated'
import { dateFormat, moneyFormat, SERVICES_URL } from '@plandi/common'

type Props = components['schemas']['Chats']

export const ShopMessageItem: FC<Props> = ({
  message,
  model,
  id: chatId,
  project,
  recipientId,
  typeChat
}) => {
  const classes = useShopMessageItemStyles()
  const theme = useTheme()
  const isTabletScreenSize = useMediaQuery(theme.breakpoints.down('md'))
  const isMobileScreenSize = useMediaQuery(theme.breakpoints.down('xs'))

  const isProjMessage = typeChat === 'Project'

  const mobileText =
    message?.content?.length > 156
      ? message?.content?.trim().slice(0, 156) + '...'
      : message?.content

  const imgSource = `https://plandi-storage.storage.yandexcloud.net/storage/${
    isProjMessage ? project?.cover : model?.cover
  }`

  const { slug_name, id } = isProjMessage ? project : model

  const chatURL = isProjMessage
    ? `${SERVICES_URL.shop}/projects/${slug_name}/${id}/chats/${chatId}`
    : `${SERVICES_URL.shop}/models/${slug_name}/${id}/chats/${chatId}`

  return (
    <Link
      href={{
        pathname: chatURL,
        query: {
          userId: recipientId
        }
      }}
    >
      <div className={classes.root}>
        <div className={classes.itemTop}>
          <div className={classes.itemInfo}>
            <Image
              width={isMobileScreenSize ? 328 : isTabletScreenSize ? 63 : 80}
              height={isMobileScreenSize ? 144 : isTabletScreenSize ? 63 : 80}
              src={imgSource}
              alt=""
              className={classes.img}
            />
            <div className={classes.itemInfoContainer}>
              <Typography variant="h3" className={classes.name}>
                {isProjMessage ? project?.name : model?.name}
              </Typography>
              <Typography variant="body1" className={classes.category}>
                {isProjMessage ? project?.category : model?.category}
              </Typography>
            </div>
          </div>
          <div className={classes.itemMetadata}>
            <p className={classes.date}>{dateFormat(message.createdAt)}</p>
            <p className={classes.price}>
              {moneyFormat(isProjMessage ? project?.cost : model?.cost)}
            </p>
          </div>
        </div>
        <div className={classes.itemBottom}>
          <p className={classes.itemText}>
            {isMobileScreenSize ? mobileText : message.content}
          </p>
        </div>
      </div>
    </Link>
  )
}

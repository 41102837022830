import { useQuery } from 'react-query'

import { useFilterBySort, useLocale } from '@plandi/common'
import { messagesApi } from '../messages.api'
import {
  ADVERTS_MESSAGES_DATA,
  messagesSortOptions
} from '../messages.constants'

export const useQueryAdvertsMessages = () => {
  const { sort } = useFilterBySort()
  const locale = useLocale()

  const query = useQuery([ADVERTS_MESSAGES_DATA, sort, locale], () =>
    messagesApi.getAdvertsMessages(locale, sort as messagesSortOptions)
  )

  return query
}

import React, { FC } from 'react'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import MenuIcon from '@material-ui/icons/Menu'
import { useNewHeaderStyles } from './new-header.styles'
import Image from 'next/image'
import Container from '@material-ui/core/Container'
import { Fade, Link, useMediaQuery, useTheme } from '@material-ui/core'
// import NextLink from 'next/link'
import Typography from '@material-ui/core/Typography'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import { useRouter } from 'next/router'
import Button from '@material-ui/core/Button'
import { ReactComponent as FlagRu } from '../../../../assets/flagRu.svg'
import { ReactComponent as FlagEn } from '../../../../assets/flagEn.svg'
import {
  getAuthSignInUrl,
  NotificationIcon,
  // getServiceURL,
  SERVICES_URL,
  useQueryUser,
  // useLocale,
  useStaticTranslate,
  useMutationLogout
  // useTranslation
} from '@plandi/common'
import { NewHeaderMobileDrawer } from './new-header-mobile-drawer'
import isEmpty from 'lodash-es/isEmpty'
import { LayoutHeaderUserMenu } from '../layout-header-user-menu'

export const NewHeader: FC = () => {
  const classes = useNewHeaderStyles()
  const marketButtonRef = React.useRef<HTMLButtonElement>(null)
  const jobButtonRef = React.useRef<HTMLButtonElement>(null)
  const langButtonRef = React.useRef<HTMLButtonElement>(null)
  const [marketDropOpen, setMarketDropOpen] = React.useState(false)
  const [jobDropOpen, setJobDropOpen] = React.useState(false)
  const [langDropOpen, setlangDropOpen] = React.useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = React.useState(false)
  const t = useStaticTranslate()
  const router = useRouter()
  // const { data: translate } = useTranslation()
  const theme = useTheme()
  const url = SERVICES_URL
  const { locale } = useRouter()
  const isSmallScreen = useMediaQuery(theme.breakpoints.down(960))
  const { data: user } = useQueryUser()
  const redirectUrl = user
    ? locale === 'ru'
      ? `${url.office}/ru` + '/projects'
      : url.office + '/projects'
    : locale === 'ru'
    ? `${url.office}/ru`
    : url.office
  // const { data: countNewNotifications } = useQueryCountNewNotifications({
  //   select: (data) =>
  //     (Object.keys(data.count ?? {}) as Array<keyof typeof data.count>).reduce(
  //       (acc, key) => acc + (data?.count?.[key] ?? 0),
  //       0
  //     )
  // })

  const mutationLogout = useMutationLogout({
    onSuccess: () => {
      router.push('/')
    }
  })

  return (
    <AppBar position="fixed" classes={{ root: classes.root }}>
      <Toolbar>
        {isSmallScreen && (
          <IconButton
            className={classes.menuIcon}
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={() => setIsDrawerOpen((prev) => !prev)}
          >
            <MenuIcon className={classes.menuIcon} color={'inherit'} />
          </IconButton>
        )}

        {/*<Typography variant="h6" noWrap>*/}
        {/*  Material-UI*/}
        {/*</Typography>*/}
        <Container className={classes.container} maxWidth={false}>
          <div className={classes.wrapper}>
            <Link href={locale === 'ru' ? `${url.main}/ru` : url.main}>
              <Image
                width="136"
                height="40"
                src="/assets/logo-header.svg"
                alt=""
                layout="fixed"
              />
            </Link>
            <nav className={classes.nav}>
              {!isSmallScreen && (
                <>
                  {/*<NextLink href={'/'} passHref>*/}
                  {/*  <Link*/}
                  {/*    color="textPrimary"*/}
                  {/*    underline="none"*/}
                  {/*    className={classes.link}*/}
                  {/*  >*/}
                  {/*    О компании*/}
                  {/*  </Link>*/}
                  {/*</NextLink>*/}
                  <Typography
                    className={classes.link}
                    onClick={() => setMarketDropOpen((prev) => !prev)}
                    ref={marketButtonRef}
                    color={marketDropOpen ? 'secondary' : 'textPrimary'}
                  >
                    {t('market')}
                    <KeyboardArrowDownIcon />
                  </Typography>
                  <Menu
                    elevation={0}
                    id="market-menu"
                    anchorEl={marketButtonRef.current}
                    keepMounted
                    open={marketDropOpen}
                    onClose={() => setMarketDropOpen(false)}
                    TransitionComponent={Fade}
                    classes={{ paper: classes.menuRoot }}
                  >
                    <MenuItem
                      className={classes.menuItem}
                      onClick={() => {
                        setMarketDropOpen(false)
                        // router.push(
                        //   locale === 'ru'
                        //     ? `${url.shop}/ru/projects`
                        //     : `${url.shop}/projects`
                        // )
                      }}
                    >
                      <Link
                        href={
                          locale === 'ru'
                            ? `${url.shop}/ru/projects`
                            : `${url.shop}/projects`
                        }
                        color="textPrimary"
                        underline="none"
                        className={classes.link}
                      >
                        {t('projects')}
                      </Link>
                    </MenuItem>

                    <MenuItem
                      className={classes.menuItem}
                      onClick={() => {
                        setMarketDropOpen(false)
                        // router.push(
                        //   locale === 'ru'
                        //     ? `${url.shop}/ru/models`
                        //     : `${url.shop}/models`
                        // )
                      }}
                    >
                      <Link
                        href={
                          locale === 'ru'
                            ? `${url.shop}/ru/models`
                            : `${url.shop}/models`
                        }
                        color="textPrimary"
                        underline="none"
                        className={classes.link}
                      >
                        {t('bim')}
                      </Link>
                    </MenuItem>

                    <MenuItem
                      className={classes.menuItem}
                      onClick={() => {
                        setMarketDropOpen(false)
                        // router.push(
                        //   locale === 'ru'
                        //     ? `${url.shop}/ru/goods`
                        //     : `${url.shop}/goods`
                        // )
                      }}
                    >
                      <Link
                        href={
                          locale === 'ru'
                            ? `${url.shop}/ru/goods`
                            : `${url.shop}/goods`
                        }
                        color="textPrimary"
                        underline="none"
                        className={classes.link}
                      >
                        {t('products')}
                      </Link>
                    </MenuItem>
                  </Menu>
                  <Typography
                    className={classes.link}
                    ref={jobButtonRef}
                    onClick={() => setJobDropOpen((prev) => !prev)}
                    color={jobDropOpen ? 'secondary' : 'textPrimary'}
                  >
                    {t('job')}
                    <KeyboardArrowDownIcon />
                  </Typography>
                  <Menu
                    elevation={0}
                    id="job-menu"
                    anchorEl={jobButtonRef.current}
                    keepMounted
                    open={jobDropOpen}
                    onClose={() => setJobDropOpen(false)}
                    TransitionComponent={Fade}
                    classes={{ paper: classes.menuRoot }}
                  >
                    <MenuItem
                      className={classes.menuItem}
                      onClick={() => {
                        setMarketDropOpen(false)
                        // router.push(
                        //   locale === 'ru'
                        //     ? `${url.job}/ru/applicants`
                        //     : `${url.job}/applicants`
                        // )
                      }}
                    >
                      <Link
                        href={
                          locale === 'ru'
                            ? `${url.job}/ru/applicants`
                            : `${url.job}/applicants`
                        }
                        color="textPrimary"
                        underline="none"
                        className={classes.link}
                      >
                        {t('tenders')}
                      </Link>
                    </MenuItem>

                    <MenuItem
                      className={classes.menuItem}
                      onClick={() => {
                        setMarketDropOpen(false)
                        // router.push(
                        //   locale === 'ru'
                        //     ? `${url.job}/ru/employers`
                        //     : `${url.job}/employers`
                        // )
                      }}
                    >
                      <Link
                        href={
                          locale === 'ru'
                            ? `${url.job}/ru/employers`
                            : `${url.job}/employers`
                        }
                        color="textPrimary"
                        underline="none"
                        className={classes.link}
                      >
                        {t('specialists')}
                      </Link>
                    </MenuItem>

                    <MenuItem
                      className={classes.menuItem}
                      onClick={() => {
                        setMarketDropOpen(false)
                        // router.push(
                        //   locale === 'ru'
                        //     ? `${url.job}/ru/companies`
                        //     : `${url.job}/companies`
                        // )
                      }}
                    >
                      <Link
                        href={
                          locale === 'ru'
                            ? `${url.job}/ru/companies`
                            : `${url.job}/companies`
                        }
                        color="textPrimary"
                        underline="none"
                        className={classes.link}
                      >
                        {t('companies')}
                      </Link>
                    </MenuItem>

                    <MenuItem
                      className={classes.menuItem}
                      onClick={() => {
                        setMarketDropOpen(false)
                        // router.push(
                        //   locale === 'ru'
                        //     ? `${url.job}/ru/factories`
                        //     : `${url.job}/factories`
                        // )
                      }}
                    >
                      <Link
                        href={
                          locale === 'ru'
                            ? `${url.job}/ru/factories`
                            : `${url.job}/factories`
                        }
                        color="textPrimary"
                        underline="none"
                        className={classes.link}
                      >
                        {t('factories')}
                      </Link>
                    </MenuItem>
                  </Menu>
                  <Link
                    color="textPrimary"
                    underline="none"
                    className={classes.link}
                    href={redirectUrl}
                  >
                    {t('office')}
                  </Link>
                  {/* <NextLink
                    href={
                      locale === 'ru'
                        ? `${url.blog}/ru/articles`
                        : `${url.blog}/articles`
                    }
                    passHref
                  > */}
                  <Link
                    href={
                      locale === 'ru'
                        ? `${url.blog}/ru/articles`
                        : `${url.blog}/articles`
                    }
                    color="textPrimary"
                    underline="none"
                    className={classes.link}
                  >
                    {t('blog')}
                  </Link>
                  {/* </NextLink> */}
                  {/* <NextLink
                    href={
                      locale === 'ru'
                        ? `${url.shop}/ru/subscriptions`
                        : `${url.shop}/subscriptions`
                    }
                    passHref
                  > */}
                  <Link
                    href={
                      locale === 'ru'
                        ? `${url.shop}/ru/subscriptions`
                        : `${url.shop}/subscriptions`
                    }
                    color="textPrimary"
                    underline="none"
                    className={classes.link}
                  >
                    {t('subscriptions')}
                  </Link>
                  {/* </NextLink> */}
                  <Button
                    ref={langButtonRef}
                    onClick={() => {
                      setlangDropOpen((prev) => !prev)
                    }}
                  >
                    {router.locale === 'ru' ? <FlagRu /> : <FlagEn />}
                    <KeyboardArrowDownIcon />
                  </Button>
                  <Menu
                    elevation={0}
                    id="lang-menu"
                    anchorEl={langButtonRef.current}
                    keepMounted
                    open={langDropOpen}
                    onClose={() => setlangDropOpen(false)}
                    TransitionComponent={Fade}
                    classes={{ paper: classes.menuRoot }}
                  >
                    <MenuItem
                      className={classes.menuItem}
                      onClick={() => {
                        if (user) {
                          mutationLogout.mutate()
                        }
                        router.push(router.asPath, undefined, {
                          locale: 'ru'
                        })
                        setlangDropOpen(false)
                      }}
                    >
                      <FlagRu /> Русский
                    </MenuItem>
                    <MenuItem
                      className={classes.menuItem}
                      onClick={() => {
                        if (user) {
                          mutationLogout.mutate()
                        }
                        router.push(router.asPath, undefined, {
                          locale: 'en'
                        })
                        setlangDropOpen(false)
                      }}
                    >
                      <FlagEn /> English
                    </MenuItem>
                  </Menu>
                </>
              )}
              {isEmpty(user) ? (
                <Button
                  color={'secondary'}
                  variant={'contained'}
                  classes={{ root: classes.signInButtonRoot }}
                  onClick={() =>
                    router.push(
                      getAuthSignInUrl(
                        locale,
                        locale === 'ru' ? `${url.main}/ru` : url.main
                      )
                    )
                  }
                >
                  {t('sign-in')}
                </Button>
              ) : (
                <div className={classes.userSection}>
                  <NotificationIcon />
                  <LayoutHeaderUserMenu />
                </div>
              )}
            </nav>
          </div>
        </Container>
      </Toolbar>
      <NewHeaderMobileDrawer
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
      />
    </AppBar>
  )
}
